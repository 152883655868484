import { Upload } from 'tus-js-client';

class TUSUploaders {
    readonly uploaders: { id: string; uploader: Upload }[] = [];

    add(id: string, uploader: Upload) {
        this.uploaders.push({ id, uploader });
    }

    rem(id: string) {
        const index = this.uploaders.findIndex((item) => item.id === id);
        if (index !== -1) {
            this.uploaders[index].uploader.abort();
            this.uploaders.splice(index, 1);
        }
    }

    uploader = (id: string) => {
        return this.uploaders.find((item) => item.id === id)?.uploader;
    };
}

const tusUploaders = new TUSUploaders();

export { tusUploaders };
