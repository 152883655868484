import { Observable } from 'rxjs';
import { tusUploaders } from './uploaders.helper';

export function tusAbort() {
    return function (source: Observable<string>): Observable<true> {
        return new Observable((subscriber) => {
            source.subscribe({
                next(uuid) {
                    tusUploaders.rem(uuid);
                    subscriber.next();
                    subscriber.complete();
                },
                error(error) {
                    subscriber.error(error);
                },
            });
        });
    };
}
