import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { UPLOADER_FEATURE_KEY } from './keys';
import { uploadReducer } from './reducers/uploader.reducer';
import { UploaderEffects } from './effects/uploader.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(UPLOADER_FEATURE_KEY, uploadReducer), EffectsModule.forFeature([UploaderEffects])],
})
export class UploaderStoreModule {}
