import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UploadState } from '../reducers/uploader.reducer';
import { UPLOADER_FEATURE_KEY } from '../keys';

const selectState = createFeatureSelector<UploadState>(UPLOADER_FEATURE_KEY);

const selectFiles = createSelector(selectState, (state: UploadState) => state.items);

const selectPendingFile = createSelector(
    selectState,
    (state: UploadState) => state.items.find((file) => ['starting', 'pending'].includes(file.status)) ?? null,
);

const selectIsWorking = createSelector(
    selectState,
    (state: UploadState) => state.items.findIndex((file) => ['starting', 'pending'].includes(file.status)) !== -1,
);

const selectFirstInQueue = createSelector(selectState, (state: UploadState) => state.items.find((file) => file.status === 'queue') ?? null);

const selectFileByUuid = (uuid: string) => createSelector(selectState, (state: UploadState) => state.items.find((item) => item.uuid === uuid) ?? null);

const selectFileStatusByUuid = (uuid: string) =>
    createSelector(selectState, (state: UploadState) => state.items.find((item) => item.uuid === uuid)?.status ?? null);

const selectFileIsProcessingByUuid = (uuid: string) =>
    createSelector(selectState, (state: UploadState) => {
        const status = state.items.find((item) => item.uuid === uuid)?.status ?? null;
        if (status === null) {
            return false;
        }
        return ['queue', 'starting', 'pending'].includes(status);
    });

export { selectFiles, selectPendingFile, selectIsWorking, selectFirstInQueue, selectFileByUuid, selectFileStatusByUuid, selectFileIsProcessingByUuid };
