import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';
import { filter } from 'rxjs';
import { selectFileByUuid, selectFileIsProcessingByUuid, selectFileStatusByUuid, selectFiles, selectPendingFile } from '../selectors/uploader.selector';
import { uploaderActions } from '../actions/uploader.actions';
import { Item } from '../models';
import { FileType } from '../types/file.type';

@Injectable({
    providedIn: 'root',
})
export class UploaderFacade {
    private readonly store = inject(Store);

    files$ = this.store.select(selectFiles);
    pending$ = this.store.select(selectPendingFile);

    file$ = (uuid: string) => this.store.select(selectFileByUuid(uuid));
    fileStatus$ = (uuid: string) => this.store.select(selectFileStatusByUuid(uuid));
    fileProcessing$ = (uuid: string) => this.store.select(selectFileIsProcessingByUuid(uuid));
    fileNonNullable$ = (uuid: string) =>
        this.store.select(selectFileByUuid(uuid)).pipe(
            filter((item): item is Item => item !== null),
            filter((item) => item.status === 'success' || item.status === 'failed'),
        );

    upload(file: File, fileType: FileType, ident?: string): string {
        const uuid = ident ?? uuidv4();
        this.store.dispatch(uploaderActions.uploadFile({ file, uuid, fileType }));
        return uuid;
    }

    abort(uuid: string): void {
        this.store.dispatch(uploaderActions.uploadAbort({ uuid }));
    }

    clear(): void {
        this.store.dispatch(uploaderActions.clear());
    }
}
