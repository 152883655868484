import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Progress } from '../models';
import { FileType } from '../types/file.type';

export const uploaderActions = createActionGroup({
    source: 'Upload',
    events: {
        'Upload File': props<{ uuid: string; file: File; fileType: FileType }>(),
        'Upload File Add To Queue': props<{ uuid: string; file: File; fileType: FileType }>(),
        'Upload File Select From Queue': props<{ uuid: string }>(),

        'Upload File Start Upload': props<{ uuid: string }>(),
        'Upload File Success': props<{ uuid: string; progress: Progress }>(),
        'Upload File Progress': props<{ uuid: string; progress: Progress }>(),
        'Upload File Error': props<{ uuid: string }>(),
        'Upload File Clear': props<{ uuid: string }>(),

        'Upload Abort': props<{ uuid: string }>(),
        'Upload Abort Success': props<{ uuid: string }>(),
        Clear: emptyProps(),
    },
});
