import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { Upload } from 'tus-js-client';
import { Progress } from '../models';
import { tusUploaders } from './uploaders.helper';
import { FileType } from '../types/file.type';
import { CHUNK_SIZE } from '../configs';

export function tusUploader(uuid: string, token: string, fileType: FileType) {
    return function (source: Observable<File>): Observable<Progress> {
        return new Observable((subscriber) => {
            source.subscribe({
                next(value) {
                    let _progress = 0;
                    const upload = new Upload(value, {
                        endpoint: environment.uploadUrl,
                        metadata: {
                            filename: value.name,
                            filetype: value.type,
                        },
                        headers: {
                            'tus-token': token,
                            'tus-type': fileType,
                        },
                        chunkSize: CHUNK_SIZE,
                        onError: (error) => {
                            subscriber.error(error);
                        },
                        onProgress: (bytesUploaded, bytesTotal) => {
                            const progress = Math.floor((bytesUploaded / bytesTotal) * 1000) / 10;
                            if (progress !== 100 && _progress !== progress) {
                                _progress = progress;
                                subscriber.next({
                                    id: upload.url?.split('/').pop() ?? null,
                                    progress,
                                    url: upload.url,
                                });
                            }
                        },
                        onSuccess: () => {
                            subscriber.next({
                                id: upload.url?.split('/').pop() ?? null,
                                progress: 100,
                                url: upload.url,
                            });
                            subscriber.complete();
                        },
                    });

                    upload.findPreviousUploads().then((previousUploads) => {
                        if (previousUploads.length) {
                            upload.resumeFromPreviousUpload(previousUploads[0]);
                        }
                        upload.start();
                    });

                    tusUploaders.add(uuid, upload);
                },
                error(error) {
                    subscriber.error(error);
                },
            });
        });
    };
}
