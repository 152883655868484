import { createReducer, on } from '@ngrx/store';
import { uploaderActions } from '../actions/uploader.actions';
import { Item } from '../models';

export interface UploadState {
    items: Item[];
}

export const initialState: UploadState = {
    items: [],
};

export const uploadReducer = createReducer(
    initialState,
    on(uploaderActions.uploadFileAddToQueue, (state, action): UploadState => {
        const index = state.items.findIndex((item) => item.uuid === action.uuid);
        const items = [...state.items];

        if (index !== -1) {
            items.splice(index, 1);
        }

        items.push({
            uuid: action.uuid,
            id: null,
            name: action.file.name,
            url: null,
            progress: 0,
            status: 'queue',
            file: action.file,
            fileType: action.fileType,
        });

        return {
            ...state,
            items,
        };
    }),

    on(uploaderActions.uploadFileSelectFromQueue, (state, action): UploadState => {
        const index = state.items.findIndex((item) => item.uuid === action.uuid);
        const items = [...state.items];

        items.splice(index, 1, {
            ...state.items[index],
            status: 'starting',
        });

        return {
            ...state,
            items,
        };
    }),

    on(uploaderActions.uploadFileProgress, (state, action): UploadState => {
        const index = state.items.findIndex((item) => item.uuid === action.uuid);
        const items = [...state.items];
        items.splice(index, 1, {
            ...state.items[index],
            ...action.progress,
            status: 'pending',
        });
        return {
            ...state,
            items,
        };
    }),

    on(uploaderActions.uploadFileSuccess, (state, action): UploadState => {
        const index = state.items.findIndex((item) => item.uuid === action.uuid);
        const items = [...state.items];
        items.splice(index, 1, {
            ...state.items[index],
            ...action.progress,
            status: 'success',
        });
        return {
            ...state,
            items,
        };
    }),

    on(uploaderActions.uploadFileError, (state, action): UploadState => {
        const index = state.items.findIndex((item) => item.uuid === action.uuid);
        const items = [...state.items];
        items.splice(index, 1, {
            ...state.items[index],
            status: 'failed',
        });
        return {
            ...state,
            items,
        };
    }),

    on(uploaderActions.uploadAbortSuccess, (state, action): UploadState => {
        const index = state.items.findIndex((item) => item.uuid === action.uuid);
        const items = [...state.items];
        items.splice(index, 1);
        return {
            ...state,
            items,
        };
    }),

    on(uploaderActions.uploadFileClear, (state, action): UploadState => {
        const index = state.items.findIndex((item) => item.uuid === action.uuid);
        const items = [...state.items];
        items.splice(index, 1);
        return {
            ...state,
            items,
        };
    }),

    on(
        uploaderActions.clear,
        (): UploadState => ({
            ...initialState,
        }),
    ),
);
