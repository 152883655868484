import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, mergeMap, of } from 'rxjs';
import { tusUploader } from '../operators/tus.operator';
import { tusAbort } from '../operators/tus-abort.operator';
import { Progress } from '../models';
import { ApiResponse } from '@app/shared/models';
import { FileType } from '../types/file.type';
@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private readonly httpClient = inject(HttpClient);

    upload(uuid: string, file: File, fileType: FileType): Observable<Progress> {
        return this.httpClient.get<ApiResponse<{ token: string }>>('api/v1/tus/token').pipe(
            mergeMap((res) => {
                return of(file).pipe(tusUploader(uuid, res.data.token, fileType));
            }),
        );
    }

    abort(uuid: string): Observable<true> {
        return of(uuid).pipe(tusAbort());
    }

    clear(uuid: string): Observable<true> {
        return of(uuid).pipe(tusAbort());
    }
}
