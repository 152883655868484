import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { of, map, mergeMap, Subject, tap, take, filter, catchError, takeUntil, repeat } from 'rxjs';
import { uploaderActions } from '../actions/uploader.actions';
import { HttpService } from '../services/http.service';
import { selectFileByUuid, selectFirstInQueue, selectIsWorking } from '../selectors/uploader.selector';
import { Store } from '@ngrx/store';
import { Item } from '../models';

@Injectable()
export class UploaderEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);
    private readonly httpService = inject(HttpService);
    private readonly stopCurrentUpload$ = new Subject<void>();

    /**
     * Abort current file upload by UUID
     */
    uploadFileAction$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(uploaderActions.uploadFile),
            concatLatestFrom((action) => this.store.select(selectFileByUuid(action.uuid))),
            mergeMap(([item, oldItem]) => {
                if (oldItem) {
                    return this.httpService.abort(oldItem.uuid).pipe(map(() => item));
                }
                return of(item).pipe(take(1));
            }),
            map((item) => uploaderActions.uploadFileAddToQueue({ ...item })),
        );
    });

    /**
     * Select item from Queue and dispath upload Select
     */
    uploadFileSelectFromQueue$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(uploaderActions.uploadFileAddToQueue, uploaderActions.uploadFileClear, uploaderActions.uploadAbortSuccess),
            concatLatestFrom(() => this.store.select(selectIsWorking)),
            filter(([, isWorking]) => isWorking === false),
            concatLatestFrom(() => this.store.select(selectFirstInQueue)),
            map(([, item]) => item),
            filter((item): item is Item => item !== null),
            map(({ uuid }) => uploaderActions.uploadFileSelectFromQueue({ uuid })),
        );
    });

    /**
     * Start Upload by uuid
     */
    uploadFileStart$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(uploaderActions.uploadFileSelectFromQueue),
            concatLatestFrom(({ uuid }) => this.store.select(selectFileByUuid(uuid))),
            map(([, item]) => item),
            filter((item): item is Item => item !== null),
            mergeMap((item) => {
                return this.httpService.upload(item.uuid, item.file, item.fileType).pipe(
                    map((data) => {
                        if (data.progress < 100) {
                            return uploaderActions.uploadFileProgress({ uuid: item.uuid, progress: data });
                        }
                        return uploaderActions.uploadFileSuccess({ uuid: item.uuid, progress: data });
                    }),
                    catchError(() => of(uploaderActions.uploadFileError({ uuid: item.uuid }))),
                );
            }),
            takeUntil(this.stopCurrentUpload$),
            repeat(),
        );
    });

    /**
     * Abort Upload by UUID
     */
    uploadAbort$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(uploaderActions.uploadAbort),
            concatLatestFrom(({ uuid }) => this.store.select(selectFileByUuid(uuid))),
            map(([, item]) => item),
            filter((item): item is Item => item !== null),
            mergeMap(({ uuid }) => {
                return this.httpService.abort(uuid).pipe(
                    map(() => {
                        return uploaderActions.uploadAbortSuccess({ uuid });
                    }),
                );
            }),
        );
    });

    /**
     * Abort Upload Process by UUID - only if current
     */
    uploadFileStartAbort$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(uploaderActions.uploadAbort),
                concatLatestFrom(({ uuid }) => this.store.select(selectFileByUuid(uuid))),
                map(([, item]) => item),
                filter((item): item is Item => item !== null),
                tap(({ status }) => {
                    if (status === 'pending' || status === 'starting') {
                        this.stopCurrentUpload$.next();
                    }
                }),
            );
        },
        { dispatch: false },
    );

    /**
     * Clear TUS Uploader on success/error
     */
    uploadSuccessCleanup$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(uploaderActions.uploadFileSuccess, uploaderActions.uploadFileError),
            mergeMap((item) => {
                return this.httpService.clear(item.uuid).pipe(map(() => item));
            }),
            map(({ uuid }) => uploaderActions.uploadFileClear({ uuid })),
        );
    });
}
